
import Vue from 'vue';
import axios from 'axios';
import { mapGetters, mapActions } from 'vuex';
import Top from '@/components/templates/Top.vue';
import SalasDetail from './SalasDetail.vue';

interface Sala {
  id: number;
  nombre: string;
  src: string;
  selectedUsers: string[];
}

export default Vue.extend({
  name: 'Salas',
  components: {
    Top,
    SalasDetail,
  },
  data() {
    return {
      search: '',
      currentPage: 1,
      itemsPerPage: 9,
      itemsPerPageOptions: [3, 6, 9, 12],
      salas: [] as Sala[],
      dialogDelete: false,
      salaToEdit: null as Sala | null,
      isNewSala: false,
      salaToDelete: null as string | null,
    };
  },
  computed: {
    ...mapGetters({
      corp: 'auth/corp',
      user: 'auth/user',
      softwareId: 'auth/softwareId'
    }),
    filteredSalas(): Sala[] {
      const searchLower = this.search.toLowerCase();
      return this.salas.filter(sala =>
        sala.nombre.toLowerCase().includes(searchLower)
      );
    },
    paginatedSalas(): Sala[] {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      return this.filteredSalas.slice(start, end);
    },
    totalPages(): number {
      return Math.ceil(this.filteredSalas.length / this.itemsPerPage);
    },
  },
  async mounted() {
    await this.refreshList();
  },
  methods: {
    ...mapActions({
      getRoomList: 'crudService/getList',
      deleteCorp: 'crudService/deleteObject',
    }),
    async refreshList() {
      try {
        const response = await axios.get('http://177.11.49.135:4000/rooms', {
          params: { corpId: this.corp.id, softwareId: this.softwareId, userId: this.user.nick }
        });
        console.log(response.data.rooms)
        const rooms = [];
        for (const room of response.data.rooms){
          const id = room.id
          const nombre = room.name
          const src = room.link
          const selectedUsers = await this.getUserList(room.name)
          console.log(selectedUsers)
          rooms.push({
            id:id,
            nombre:nombre,
            src:src,
            selectedUsers:selectedUsers
          })
        }
        console.log(rooms)
        this.salas = rooms
        console.log(this.salas)
      } catch (error) {
        console.error('Error fetching room list:', error);
        this.salas = [];
      }
    },
    editSala(sala: Sala) {
      this.salaToEdit = sala;
      this.isNewSala = false;
      (this.$refs.salasDetail as any).dialog = true;
    },
    joinRoom(room) {
      const baseUrl = 'https://meet.voipgroup.com';
      const src = `/${this.corp.id}/${this.softwareId}/2/${room.nombre}`
      const fullUrl = `${baseUrl}${src}`;
      window.open(fullUrl, '_blank');
    },
    confirmDelete(name: string) {
      this.salaToDelete = name;
      this.dialogDelete = true;
    },
    async deleteItemConfirm() {
      if (this.salaToDelete) {
        try {
          const response = await axios.delete('http://177.11.49.135:4000/rooms', {
            data: {
              name: this.salaToDelete,
              corpId: this.corp.id,
              softwareId: this.softwareId
            }
          });
          if (response.status < 300) {
            this.refreshList();
            this.dialogDelete = false;
            this.salaToDelete = null;
            this.$store.dispatch("snackbar/showSnack", {
              message: "Sala deleted successfully",
              color: "success",
              timeout: 3000,
            });
          } else {
            this.$store.dispatch("snackbar/showSnack", {
              message: "Failed to delete sala",
              color: "error",
              timeout: 3000,
            });
          }
        } catch (error) {
          console.error('Error deleting sala:', error);
          this.$store.dispatch("snackbar/showSnack", {
            message: "Error deleting sala",
            color: "error",
            timeout: 3000,
          });
        }
      }
    },
    handleCreateSala(sala: Sala) {
      this.refreshList(); // Refresh the list after creating a new sala
    },
    openNewSalaDialog() {
      this.salaToEdit = null;
      this.isNewSala = true;
      (this.$refs.salasDetail as any).dialog = true;
    },
    goToCalendar() {
      this.$router.push({ name: 'calendar' });
    },
    async getUserList(name: string) {
      console.log(name)
      console.log(this.corp.id)
      console.log(this.softwareId)
      const responseA = await axios.post("http://177.11.49.135:4000/rooms/users", {
        name: name,
        corpId: this.corp.id,
        softwareId: this.softwareId
      });
      console.log(responseA)
      return responseA.data;
    },
  },
});
