
import Vue from 'vue';
import axios from 'axios';
import { mapGetters } from 'vuex';
import i18n from "@/plugins/i18n";
import * as action from "@/store/actions";
interface Sala {
  id?: number;
  name: string;
  userOption: string;
	selectedUsers: string[];
}

export default Vue.extend({
  name: 'SalasDetail',
  props: {
    isToNew: Boolean,
    editRoom: Object as () => Sala,
  },
  data() {
    return {
      selectedUsers: [],
      userList: [],
      search: '',
      dialog: false,
      showUserListDialog: false,
      sala: {} as Sala,
      originalName: '', // New property to store the original name
      valid: false,
      filteredUserList: [],
      rules: {
        required: (v) => !!v || i18n.t("loginView.required"),
        nameRules: (v: string) => (v && v.length > 3 && v.length < 250) || i18n.t("misc.roomLength"),
      },
    };
  },
  computed: {
    ...mapGetters({
      corp: 'auth/corp',
      user: 'auth/user',
      softwareId: 'auth/softwareId',
    }),
  },
  mounted() {
		this.fetchUserList();
	},
  watch: {
    selectedUsers(newVal) {
			this.sala.selectedUsers = newVal;
		},
    isToNew: {
      handler(newVal) {
        if (newVal) {
          this.sala = { name: '' } as Sala;
          this.sala.selectedUsers = [];
          this.originalName = ''; // Reset original name
        }
      },
      immediate: true,
    },
    editRoom: {
      handler(newVal) {
        if (newVal && !this.isToNew) {
          var sala = { ...newVal };
          this.sala.name = sala.nombre
          this.sala.selectedUsers = sala.selectedUsers
          this.originalName = newVal.nombre; // Store the original name
          this.dialog = true;
        }
      },
      immediate: true,
    },
  },
  methods: {
    async fetchUserList() {
			try {
				const token = localStorage.getItem('token');
				const response = await axios.get(action.USER_CONTROLLER_NO_LEVEL, {
					headers: {
						Authorization: `Bearer ${token}`, // Agrega el token al header
					},
				});
        this.userList = response.data.filter(user => user.level !== 1 && user.level !== 5);
			} catch (error) {
				console.error('Error fetching user list:', error);
			}
		},
    closeDialog() {
      this.dialog = false;
      this.$emit('refreshList');
    },
    async validate(event: Event) {
      event.preventDefault(); // Prevent default form submission
      if (this.sala.userOption == "allUsers") {
				this.sala.selectedUsers = this.userList.map(user => user.name);
			}
      if (this.valid) {
        try {
          if (this.isToNew) {
            await axios.post('http://177.11.49.135:4000/rooms', {
              name: this.sala.name,
              corpId: this.corp.id,
              softwareId: this.softwareId,
              userId: this.sala.selectedUsers
            });
            this.$emit('create-sala', { name: this.sala.name });
          } else {
            await axios.put('http://177.11.49.135:4000/rooms', {
              name: this.originalName,
              corpId: this.corp.id,
              softwareId: this.softwareId,
              newName: this.sala.name,
              userId: this.sala.selectedUsers
            });
            this.$emit('refreshList');
          }

          this.closeDialog();
          this.$store.dispatch('snackbar/showSnack', {
            message: this.isToNew ? 'Sala created successfully' : 'Sala updated successfully',
            color: 'success',
            timeout: 3000,
          });
        } catch (error) {
          this.$store.dispatch('snackbar/showSnack', {
            message: 'Failed to save Sala',
            color: 'error',
            timeout: 3000,
          });
        }
      }
    },
    openUserListDialog() {
      console.log("asd")
  		this.userList.forEach(user => {
				if (user.id === this.user.id || this.sala.selectedUsers.includes(user.name)) { //(user.id === this.user.id |)
					user.selected = true;
				} else {
					user.selected = false;
				}
			});
      console.log("asd")
			this.filteredUserList = this.userList
			this.showUserListDialog = true;
		},
    filterUserList() {
			if (this.search == '') {
				this.filteredUserList = this.userList
			}
			else {
				this.filteredUserList = this.userList.filter(user =>
					user.name.toLowerCase().includes(this.search.toLowerCase())
				);
			}
		},
    acceptUserSelection() {
			this.sala.selectedUsers = this.userList.filter(user => user.selected).map(user => user.name);
			this.showUserListDialog = false;
		},
  },
});
