
import Vue from "vue";
import axios from "axios";
import * as action from "@/store/actions";
import i18n from "@/plugins/i18n";
import { mapGetters } from 'vuex';
import moment from 'moment';
interface Meet {
	id: number;
	name: string;
	date: string;
	start: string;
	end: string;
	src: string;
	userOption: string;
	selectedUsers: string[];
	topic: string;
}
export default Vue.extend({
	name: "CalendarDetail",
	props: ["calendarToUpdate", "isToEdit", "isToNew", "selectedEvent", "selectedDateDetail"],
	data: () => ({
		search: '',
		createMeetingDialog: false,
		isFormValid: false,
		newMeeting: {} as Meet,
		salaToEdit: {},
		startTimeMenu: false,
		endTimeMenu: false,
		showUserListDialog: false,
		selectedUsers: [],
		userList: [],
		filteredUserList: []
	}),
	watch: {
		selectedUsers(newVal) {
			this.newMeeting.selectedUsers = newVal;
		},
		'newMeeting.start'(val) {
			console.log(val)
        	this.$refs.endTimeMenu.validate(); // Forzar la validación del campo de fin cuando cambie el inicio
			this.$refs.startTimeMenu.validate();
		},
		'newMeeting.end'(val) {
			console.log(val)
			this.$refs.startTimeMenu.validate(); // Forzar la validación del campo de inicio cuando cambie el fin
			this.$refs.endTimeMenu.validate();
		}
	},
	computed: {
		...mapGetters({
			corp: 'auth/corp',
			user: 'auth/user',
			softwareId: 'auth/softwareId',
			menuIconColor: "lookAndFeel/menuIconColor",
		}),
	},
	mounted() {
		this.fetchUserList();
	},
	methods: {
		async fetchUserList() {
			try {
				const token = localStorage.getItem('token');
				const response = await axios.get(action.USER_CONTROLLER_NO_LEVEL, {
					headers: {
						Authorization: `Bearer ${token}`, // Agrega el token al header
					},
				});
				this.userList = response.data.filter(user => user.level !== 1 && user.level !== 5);
			} catch (error) {
				console.error('Error fetching user list:', error);
			}
		},
		validateDateRange(){
			const now = moment.utc()
			const meetingDate = moment.utc(this.newMeeting.date);
			if (meetingDate.isSameOrAfter(now, 'day')) {
				return true
			}
			else{
				return this.$t('misc.dateBeforeNow');
			}
		},
		validateTimeRange() {
			const start = this.newMeeting.start;
			const end = this.newMeeting.end;
			if (!start || !end) return true; // Si uno no está definido, no validar

			// Comparar correctamente las horas de inicio y fin
			const startTime = new Date(`1970-01-01T${start}`);
			const endTime = new Date(`1970-01-01T${end}`);
			console.log("asd")
			return startTime < endTime || this.$t('misc.startBeforeEnd');
		},
		acceptUserSelection() {
			this.newMeeting.selectedUsers = this.userList.filter(user => user.selected).map(user => user.name);
			this.showUserListDialog = false;
		},
		submitForm() {
			this.$refs.form.validate();
			if (this.isFormValid) {
				this.createMeetingDialog = false;
			}
		},
		filterUserList() {
			if (this.search == '') {
				this.filteredUserList = this.userList
			}
			else {
				this.filteredUserList = this.userList.filter(user =>
					user.name.toLowerCase().includes(this.search.toLowerCase())
				);
			}
		},
		openUserListDialog() {
			this.userList.forEach(user => {
				if (user.id === this.user.id || this.newMeeting.selectedUsers.includes(user.name)) {
					user.selected = true;
				} else {
					user.selected = false;
				}
			});
			this.filteredUserList = this.userList
			this.showUserListDialog = true;
		},
		async createMeeting() {
			if (this.newMeeting.userOption == "allUsers") {
				this.newMeeting.selectedUsers = this.userList.map(user => user.name);
			}
			try {
				if (this.isFormValid) {
					if (this.isToNew) {
						await axios.post('http://177.11.49.135:4000/DateRooms/create', {
							corpId: this.corp.id,
							name: this.newMeeting.name,
							softwareId: this.softwareId,
							start: `${this.newMeeting.date} ${this.newMeeting.start}`,
							end: `${this.newMeeting.date} ${this.newMeeting.end}`,
							userOption: this.newMeeting.userOption,
							userIds: this.newMeeting.selectedUsers,
							creator: this.user.nick,
							topic: this.newMeeting.topic
						})
							.then(response => {
								this.$store.dispatch('snackbar/showSnack', {
									message: i18n.t('misc.created'),
									color: 'success',
									timeout: 3000,
								})
								this.$emit('meetingCreated');
								this.createMeetingDialog = false;
								this.dialog = false
							})
							.catch(response => {
								this.$store.dispatch('snackbar/showSnack', {
									message: i18n.t('misc.defaultError'),
									color: 'error',
									timeout: 3000,
								})
							})
					} else {
						await axios.put("http://177.11.49.135:4000/DateRooms/edit", {
							roomNew: this.newMeeting,
							roomOld: this.salaToEdit,
							softwareId: this.softwareId,
							corpId: this.corp.id,
							listUser: this.newMeeting.selectedUsers,
							creator: this.user.nick
						})
							.then(response => {
								this.$store.dispatch('snackbar/showSnack', {
									message: i18n.t('misc.updated'),
									color: 'success',
									timeout: 3000,
								})
								this.$emit('meetingCreated');
								this.$emit('closeDialog');
								this.createMeetingDialog = false;
								this.dialog = false
							})
							.catch(response => {
								this.$store.dispatch('snackbar/showSnack', {
									message: i18n.t('misc.defaultError'),
									color: 'error',
									timeout: 3000,
								})
							})
					}
				}
			} catch (error) {
				console.error('Error creating/updating meeting:', error);
			}
		},
		editMeet(event) {
			this.salaToEdit = event;
			this.newMeeting.name = event.name
			this.newMeeting.topic = event.topic
			this.newMeeting.date = event.date
			this.newMeeting.start = event.start.split("T")[1]
			this.newMeeting.end = event.end.split("T")[1]
			this.newMeeting.userOption = event.userOption
			this.newMeeting.selectedUsers = event.selectedUsers
			this.isToNew = false;
			this.createMeetingDialog = true;
		},
		openCreateMeetingDialog() {
			this.isFormValid = true,
			this.newMeeting = {} as Meet;
			this.newMeeting.date = this.formatDate(this.selectedDateDetail)
			this.isToNew = true;
			this.selectedUsers = [];
			this.createMeetingDialog = true;
		},
		formatDate(date) {
			const d = new Date(date);
			const month = String(d.getMonth() + 1).padStart(2, '0');
			const day = String(d.getDate()).padStart(2, '0');
			const year = d.getFullYear();
			return `${year}-${month}-${day}`;
		}
	}
})
